/* RESET */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr,
acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong,
sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, button,
input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	outline: none;
	box-sizing: border-box;
	font: inherit;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
	height: 100vh;
	box-sizing: border-box;
}
body {
	background: var(--apColorLightGray);
	height: 100vh;
	font-family: 'Inter';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
/*	letter-spacing: 0.03em; */
	color: var(--apColorDark);
	/* overflow-y: scroll; */
	box-sizing: border-box;

}
/* body>div {
	overflow: auto;
} */
#root {
	box-sizing: border-box;
	height: 100vh;
}
.App {
	height: 100vh;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


@import './fonts/style.css';