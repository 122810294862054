
:root {
    --color-brand:rgba(56, 53, 71, 1);
    --color-black:rgba(0, 0, 0, 1);
    --color-white:rgba(255, 255, 255, 1);
    --color-gray1:rgba(142, 142, 147, 1);
    --color-gray3:rgba(199, 199, 204, 1);
    --color-gray6:rgba(242, 242, 247, 1);
    --color-blue:rgba(0, 122, 255, 1);
    --color-lightBlue:rgba(217, 235, 255, 1);
    --color-green:rgba(33, 150, 83, 1);
    --color-indigo:rgba(88, 86, 214, 1);
    --color-orange:rgba(255, 149, 0, 1);
    --color-pink:rgba(255, 45, 85, 1);
    --color-purple:rgba(175, 82, 222, 1);
    --color-red:rgba(255, 59, 48, 1);
    --color-teal:rgba(90, 200, 250, 1);
    --color-yellow:rgba(255, 204, 0, 1);
    --color-system-background-primary:rgba(255, 255, 255, 1);
    --color-system-background-secondary:rgba(242, 242, 247, 1);
    --color-system-background-tertiary:rgba(255, 255, 255, 0.7);
    --color-label-primary:rgba(0, 0, 0, 1);
    --color-label-secondary:rgba(60, 60, 67, 0.6);
    --color-label-tertiary:rgba(60, 60, 67, 0.36);
    --color-label-quarternary:rgba(60, 60, 67, 0.18);
    --color-separator-opaque:rgba(198, 198, 200, 1);
    --color-separator-non-opaque:rgba(60, 60, 67, 0.36);
    --color-grouped-background-primary:rgba(242, 242, 247, 1);
    --color-grouped-background-secondary:rgba(255, 255, 255, 1);
    --color-grouped-background-tertiary:rgba(209, 209, 214, 1);
    --color-fill-primary:rgba(120, 120, 128, 0.2);
    --color-fill-secondary:rgba(120, 120, 128, 0.16);
    --color-fill-tertiary:rgba(118, 118, 128, 0.12);
    --color-fill-quarternary:rgba(116, 116, 128, 0.08);
    --color-disabled:rgba(151, 149, 146, 1);
}
.largeText{
    font-family: 'Inter Medium';
    font-size: 64px;
    line-height: 150%;
}
.title1{
    font-family: 'Inter Medium';
    font-size: 24px;
    line-height: 150%;
}
.title2{
    font-family: 'Inter Medium';
    font-size: 20px;
    line-height: 150%;
    /* identical to box height, or 114% */
}
.title3{
    font-family: 'Inter Semi Bold';
    font-size: 16px;
    line-height: 100%;
}
.title4{
    font-family: 'Inter Semi Bold';
    font-size: 14px;
    line-height: 120%;
}
.text1{
    font-family: 'Inter Regular';
    font-size: 16px;
    line-height: 150%;
}
.text2{
    font-family: 'Inter Semi Bold';
    font-size: 16px;
    line-height: 150%;
}
.text3{
    font-family: 'Inter Regular';
    font-size: 14px;
    line-height: 120%;
}
.caption1{
    font-family: 'Inter Semi Bold';
    font-size: 12px;
    line-height: 130%;
}
.caption2{
    font-family: 'Inter Regular';
    font-size: 12px;
    line-height: 130%;
}

.button-text{
    font-family: 'Inter Regular';
    font-size: 18px;
    line-height: 100%;
}

.input{
    border: none;
    border-bottom: 1px solid;
    padding: 12px 0;
    color:var(--color-label-secondary)
}
.input:focus-within{
    color:var(--color-label-primary)
}

.input.error{
    border-bottom-color:var(--color-red);
}
.input .errorText{
    color: var(--color-red);
}

.input input{
    width: 100%;
}
.button{
    border-radius: 8px;
    padding: 15px ; 
    background-color: var(--color-blue);
    color:var(--color-white);
}
.button:hover{
    opacity: 0.8;
    cursor: pointer;
}

.link{
    cursor: pointer;
    color: var(--color-blue);
}
.link:hover{
    text-decoration: underline;
}
.icon{
    min-width: 40px;
    min-height: 40px;
    padding: 8px;
    cursor: pointer;
}
.icon.btn{
    padding: 12px;
    min-width: 48px;
    min-height: 48px;
}
.icon div{
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
}
.subIcon{
    align-self: center;
    margin-left: 9px;
}
.subIcon div{
    width: 16px;
    height: 16px;
  
}
.statusType{
    align-self: center;
    margin-left: 14px;
    margin-right: 6px;
}
.statusType div{
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
}

.icon:hover{
    background-color: var(--color-fill-tertiary);
    border-radius: 8px;
}

.avatar{
    width: 40px;
    min-width: 40px;
    max-height: 40px;
    border-radius: 8px;
    text-align: center;
    padding-top: 5px;  
    padding-bottom: 5px;
    cursor: pointer;
    color:white;
    filter: drop-shadow(0px 1px 3px rgba(34, 34, 34, 0.2));
}




























/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////////// */
.App{
    display: flex;
    flex-direction: row;
}
.loginBlock{
    height: 100vh;
    width: 100%;
    background-image: url('assets/img/Logo1.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--color-system-background-secondary);
    display: flex;
    align-items: center;
  }

  .loginForm{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    width: 448px;
    box-shadow: 0px 10px 26px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
}
.loginForm .input{
    margin: 4px 44px;
}
.loginForm .button{
    margin: 32px 44px;
}
.loginLogo{
    display: inline-block;
    width:118px;
    height:118px;
    margin: 44px auto 32px;
    background-image: url('assets/img/LogoBrand.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;    
}
.aditionalLogin{
    margin: 0 44px 44px;
}
.sidebar{
    min-width:380px;
    height: 100vh;
    overflow-y:'auto';
    display: flex;
    flex-direction: column;
    background-color: var(--color-fill-secondary);
    color: var(--color-label-primary);
    border-right: 1px solid var(--color-separator-opaque);
}

.sidebarHeader{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--color-white);
}
.sidebarHeader .avatar{
    margin-left: 24px;
}
.sidebarOptions{    
    display: flex;  
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 24px 16px 0;
}
.sidebarOptions .icon{
    margin-left: 8px;
}
.sidebarSeacrh{
    margin: 18px 0px 16px 24px ;
    padding: 8px 12px 8px 36px;
    border-radius: 10px 0 0 10px;
    background-color: var(--color-fill-tertiary);
    position: relative;
}
.sidebarSeacrh::after{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url('./assets/img/ic_magnifyingglass.svg');
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 50%;
}
.sidebarSeacrhIcon{
    margin: 18px 24px 16px 0px;
    border-radius: 0 10px 10px 0px;
    background-color: var(--color-fill-tertiary);
}
.sidebarSection .title4{
    display: block;
    padding: 16px 24px 8px;
}
.sidebarLink{
    padding: 8px 24px;
    display: flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-white);
    position: relative;
    color: var(--color-brand);
}

.moreButoon{
    display: none;
    position: absolute;
    right: 8px;
}

.sidebarLink:hover{
    background-color: var(--color-fill-tertiary);
    cursor: pointer;
}
.sidebarLink:hover .unreaded{
    right: 56px;
}
.sidebarLink:hover .moreButoon{
    display: block;
}


.sidebarActive{
    background-color: var(--color-lightBlue);
}
.sidebarLink .title3{
    margin: 0 32px;
}

.unreaded{
    display: flex;
    padding: 1px 6px;
    border-radius: 16px;
    background: var(--color-label-secondary);
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: white;
    position: absolute;
    right: 24px;
}
.mainLine{
    /* overflow-y: auto; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

}
.chatHeader{    
    margin: 16px 0 16px 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    background-color: var(--color-system-background-primary);
}
.chatHeader .chatHeaderSub{
    display: flex;
    flex-direction: row;
    align-items:center;
}
.chatList{
    margin-top: 72px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--color-system-background-secondary);
}
.chatMsg{
    display: flex;
    flex-direction: row;
    margin: 0 16px 20px 8px;
    padding: 8px 16px;
    border-radius: 8px;
    position: relative;
}

.chatMsg:hover{
    background-color: var(--color-fill-tertiary);
}
.chatMsg:hover .msgButoon{
    visibility:visible;
}
.msgBody{
    display: flex;
    flex-direction: column;
    margin-left: 9px;
}
.msgText{
    background-color: var(--color-system-background-primary);
    padding: 12px;
    border-radius: 0px 8px 8px 8px;
}
.textWithImg{
    border-radius: 0px 8px 0px 0px;
}
.imgWithText{
    border-radius: 0px 0px 8px 8px;
}

.sysyemMsg{
    color: var(--color-red);
    margin: 0 0 20px 72px;
    position: relative;
}
.sysyemMsg::after{
    content: '';
    position: absolute;
    height: 1px;
    background-color: var(--color-red);
    width: 100%;
    top: 50%;
}
.chatInputContainer{
    padding: 24px;
    display: flex;
    flex-direction: row;
}
.chatInput{
    padding: 12px 44px 12px 16px;
    width: -webkit-fill-available;
    margin: 0 8px;
    background-color: var(--color-fill-quarternary);
}
.toggleDown{
    position: absolute ;
    color:white;
    right:10%;
    bottom:20%;
    background:blue;
    border-radius:50%;
    height:25px;
    width:25px;
}
.toggleDown:hover{
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px var(--color-fill-tertiary);
}
.readedStatus{
    width: 16px;
    height: 16px;
    background-image: url('./assets/img/ic_message-check.svg');
    background-repeat: no-repeat;
    background-size: cover;
}
.msgInfo{
    display: flex;
    flex-direction: row;
}
.msgButoon{
    display: block;
    visibility: hidden;
    align-self: start;
    position: relative;
}
.moreModal{
    position: absolute;
    left: 0;
}










.searchIcon{
    background-image: url('./assets/img/ic_search.svg');
}

.createIcon{
    background-image: url('./assets/img/ic_add-channel.svg');
}

.logoutIcon{
    background-image: url('./assets/img/ic_log-out.svg');
}
.moreIcon{
    background-image: url('./assets/img/ic_more-horizontal.svg');
}

.mutedIcon{
    background-image: url('./assets/img/ic_volume-x.svg');
}
.publicIcon{
    background-image: url('./assets/img/Public.svg');
}
.privateIcon{
    background-image: url('./assets/img/Private.svg');
}
.sendIcon{
    background-image: url('./assets/img/ic_send.svg');
}
.closeIcon{
    background-image: url('./assets/img/ic_cancel-x-big.svg');
}
.attachIcon{
    background-image: url('./assets/img/ic_paperclip.svg');
}
.online{
    border-radius: 50%;
    background-color: var(--color-green);
}
.ofline{
    border-radius: 50%;
    background-color: var(--color-gray3);
}
.invisible{
    border-radius: 50%;
    background-color: var(--color-gray1);
}
.busy{
    border-radius: 50%;
    background-color: var(--color-red);
}
.departed{
    border-radius: 50%;
    background-color: var(--color-yellow);
}
.faworite{
    background-image: url('./assets/img/ic_faworite-star.svg');
}
.unfaworite{
    background-image: url('./assets/img/ic_unfaworite-star.svg');
}